<template>
    <StudentsLessonTable :selectedStudent="userData"></StudentsLessonTable>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import StudentsLessonTable from "./StudentsLessonTable.vue"
export default {
  data: () => ({}),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  components:{
    StudentsLessonTable
  },
  methods: {},
};
</script>
